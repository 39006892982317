import React from 'react';
import logo from './web3vm-sitelogo.png';
import './App.css';
import Portis from '@portis/web3';
import Web3 from 'web3';
import Iframe from 'react-iframe'

const portis = new Portis('6047b015-8dab-4f06-ae6a-1ab830e2a749', 'mainnet');
const web3 = new Web3(portis.provider);

require('events').EventEmitter.defaultMaxListeners = 25


web3.eth.getTransactionFromBlock('latest', 1, function(error, result) {if(!error) console.log("Data for Latest Block Index Position 1: " + JSON.stringify(result)); else console.error(error);})
web3.eth.getBlockTransactionCount("0x2d7a13fb9e6a23e933beb5909d77fcde8109f7321242052ff4b72c6a1d8f34fd", function(err, result) {if (err) {console.log(err)} else {console.log("The Ethereum blockchain reports " + result + " Transactions from BLOCKHASH: 0x2d7a13fb9e6a23e933beb5909d77fcde8109f7321242052ff4b72c6a1d8f34fd, TRANSACTION HASH: 0x4eaa6a224c634720f5e78f31306c8657a89c552c61029510b581205a2027841a")}})
web3.eth.getCoinbase(function(err, result) {if (err) {console.log(err)} else {console.log("This is the account that will receive mining rewards: " + result)}})
web3.eth.getGasPrice(function(err, result) {if (err) {console.log(err)} else {console.log("Current Ethereum Network Gas Price is: " + web3.utils.fromWei(result, "gwei") + " Gwei")}})
web3.eth.getBlock(10000000, function(error, result) {if(!error) console.log("Data for block 10000000: " + JSON.stringify(result)); else console.error(error);})
web3.eth.getBlockNumber(function(err, result) {if (err) {console.log(err)} else {console.log("The current WorldLaw Block Number is: " + result)}})
web3.eth.estimateGas({to: "0x11f4d0A3c12e86B4b5F39B213F7E19D048276DAe",data:"0xc6888fa10000000000000000000000000000000000000000000000000000000000000003"}).then(function (txgas) {console.log("Estimated Ethereum Gas to Run the Data is: " + txgas + " Wei");})
web3.eth.getTransaction('0x79835c9b82bb595fca2d264991d44eb6d350d6367b1ebeaa6f197a34cd2d1ccd', function(error, result) {if(!error) console.log("The Transaction Data for Hash 0x79835c9b82bb595fca2d264991d44eb6d350d6367b1ebeaa6f197a34cd2d1ccd is: " + JSON.stringify(result)); else console.error(error);})
web3.eth.net.getPeerCount(function(err, result) {if (err) {console.log(err)} else {console.log("The WorldLaw Network currently has " + result + " peer nodes operational.")}})

web3.eth.ens.getAddress('Qfinney.eth').then(function (address) {console.log("The Ethereum Address for Qfinney.eth is: " + address);})
web3.eth.ens.getAddress('Ufinney.eth').then(function (address) {console.log("The Ethereum Address for Ufinney.eth is: " + address);})
web3.eth.ens.getAddress('Ifinney.eth').then(function (address) {console.log("The Ethereum Address for Ifinney.eth is: " + address);})
web3.eth.ens.getAddress('Cfinney.eth').then(function (address) {console.log("The Ethereum Address for Cfinney.eth is: " + address);})
web3.eth.ens.getAddress('Kfinney.eth').then(function (address) {console.log("The Ethereum Address for Kfinney.eth is: " + address);})
web3.eth.ens.getAddress('Bfinney.eth').then(function (address) {console.log("The Ethereum Address for Bfinney.eth is: " + address);})
web3.eth.ens.getAddress('Efinney.eth').then(function (address) {console.log("The Ethereum Address for Efinney.eth is: " + address);})
web3.eth.ens.getAddress('Afinney.eth').then(function (address) {console.log("The Ethereum Address for Afinney.eth is: " + address);})
web3.eth.ens.getAddress('Mfinney.eth').then(function (address) {console.log("The Ethereum Address for Mfinney.eth is: " + address);})
web3.eth.ens.getAddress('Sfinney.eth').then(function (address) {console.log("The Ethereum Address for Sfinney.eth is: " + address);})

web3.eth.getTransactionCount("0x0BD4D9a804418Bab7c132fd0b5164Aff580Be70e", function(err, result) {if (err) {console.log(err)} else {console.log("The Qfinney account with public address: 0x0BD4D9a804418Bab7c132fd0b5164Aff580Be70e has executed " + result + " transactions")}})
web3.eth.getTransactionCount("0x0095e9Ce5b15f3193EB0a213ed600eA4Be6691c6", function(err, result) {if (err) {console.log(err)} else {console.log("The Ufinney account with public address: 0x0095e9Ce5b15f3193EB0a213ed600eA4Be6691c6 has executed " + result + " transactions")}})
web3.eth.getTransactionCount("0x00CfCA7A675E51c89d88000BBF6E63bD773483A1", function(err, result) {if (err) {console.log(err)} else {console.log("The Ifinney account with public address: 0x00CfCA7A675E51c89d88000BBF6E63bD773483A1 has executed " + result + " transactions")}})
web3.eth.getTransactionCount("0x0bEb62405D997ae47b2DD75E17523A6358b7e29C", function(err, result) {if (err) {console.log(err)} else {console.log("The Cfinney account with public address: 0x0bEb62405D997ae47b2DD75E17523A6358b7e29C has executed " + result + " transactions")}})
web3.eth.getTransactionCount("0x00cFb9339DC324E887baBd2f256b0Fc3A6D4919F", function(err, result) {if (err) {console.log(err)} else {console.log("The Kfinney account with public address: 0x00cFb9339DC324E887baBd2f256b0Fc3A6D4919F has executed " + result + " transactions")}})
web3.eth.getTransactionCount("0x0206Aed9FFc636184D54f342892F4905d04656Ba", function(err, result) {if (err) {console.log(err)} else {console.log("The Bfinney account with public address: 0x0206Aed9FFc636184D54f342892F4905d04656Ba has executed " + result + " transactions")}})
web3.eth.getTransactionCount("0xCB9afaB1035B5A4bC45ae02Cf4Ff9759b9547F9F", function(err, result) {if (err) {console.log(err)} else {console.log("The Efinney account with public address: 0xCB9afaB1035B5A4bC45ae02Cf4Ff9759b9547F9F has executed " + result + " transactions")}})
web3.eth.getTransactionCount("0x16DF16d3F24be0B68d7aE8F6C4a2bAEe3C3776fe", function(err, result) {if (err) {console.log(err)} else {console.log("The Afinney account with public address: 0x16DF16d3F24be0B68d7aE8F6C4a2bAEe3C3776fe has executed " + result + " transactions")}})
web3.eth.getTransactionCount("0x6DdeE47E3510D046d6ee8EC5b5f6Db50F15c7a1F", function(err, result) {if (err) {console.log(err)} else {console.log("The Mfinney account with public address: 0x6DdeE47E3510D046d6ee8EC5b5f6Db50F15c7a1F has executed " + result + " transactions")}})
web3.eth.getTransactionCount("0x92B876f08773AdEB6C289D9a89f3cDE54fa698E3", function(err, result) {if (err) {console.log(err)} else {console.log("The Sfinney account with public address: 0x92B876f08773AdEB6C289D9a89f3cDE54fa698E3 has executed " + result + " transactions")}})

web3.eth.getBalance("0x0BD4D9a804418Bab7c132fd0b5164Aff580Be70e", function(err, result) {if (err) {console.log(err)} else {console.log("The 0x0BD4D9a804418Bab7c132fd0b5164Aff580Be70e account has " + web3.utils.fromWei(result, "finney") + " Qfinney Pawns which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})
web3.eth.getBalance("0x0095e9Ce5b15f3193EB0a213ed600eA4Be6691c6", function(err, result) {if (err) {console.log(err)} else {console.log("The 0x0095e9Ce5b15f3193EB0a213ed600eA4Be6691c6 account has " + web3.utils.fromWei(result, "finney") + " Ufinney Pawns which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})
web3.eth.getBalance("0x00CfCA7A675E51c89d88000BBF6E63bD773483A1", function(err, result) {if (err) {console.log(err)} else {console.log("The 0x00CfCA7A675E51c89d88000BBF6E63bD773483A1 account has " + web3.utils.fromWei(result, "finney") + " Ifinney Pawns which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})
web3.eth.getBalance("0x0bEb62405D997ae47b2DD75E17523A6358b7e29C", function(err, result) {if (err) {console.log(err)} else {console.log("The 0x0bEb62405D997ae47b2DD75E17523A6358b7e29C account has " + web3.utils.fromWei(result, "finney") + " Cfinney Pawns which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})
web3.eth.getBalance("0x00cFb9339DC324E887baBd2f256b0Fc3A6D4919F", function(err, result) {if (err) {console.log(err)} else {console.log("The 0x00cFb9339DC324E887baBd2f256b0Fc3A6D4919F account has " + web3.utils.fromWei(result, "finney") + " Kfinney Pawns which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})
web3.eth.getBalance("0x0206Aed9FFc636184D54f342892F4905d04656Ba", function(err, result) {if (err) {console.log(err)} else {console.log("The 0x0206Aed9FFc636184D54f342892F4905d04656Ba account has " + web3.utils.fromWei(result, "finney") + " Bfinney Pawns which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})
web3.eth.getBalance("0xCB9afaB1035B5A4bC45ae02Cf4Ff9759b9547F9F", function(err, result) {if (err) {console.log(err)} else {console.log("The 0xCB9afaB1035B5A4bC45ae02Cf4Ff9759b9547F9F account has " + web3.utils.fromWei(result, "finney") + " Efinney Pawns which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})
web3.eth.getBalance("0x16DF16d3F24be0B68d7aE8F6C4a2bAEe3C3776fe", function(err, result) {if (err) {console.log(err)} else {console.log("The 0x16DF16d3F24be0B68d7aE8F6C4a2bAEe3C3776fe account has " + web3.utils.fromWei(result, "finney") + " Afinney Pawns which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})
web3.eth.getBalance("0x6DdeE47E3510D046d6ee8EC5b5f6Db50F15c7a1F", function(err, result) {if (err) {console.log(err)} else {console.log("The 0x6DdeE47E3510D046d6ee8EC5b5f6Db50F15c7a1F account has " + web3.utils.fromWei(result, "finney") + " Mfinney Pawns which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})
web3.eth.getBalance("0x92B876f08773AdEB6C289D9a89f3cDE54fa698E3", function(err, result) {if (err) {console.log(err)} else {console.log("The 0x92B876f08773AdEB6C289D9a89f3cDE54fa698E3 account has " + web3.utils.fromWei(result, "finney") + " Sfinney Pawns which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})




function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo img-fluid50pct" alt="logo" />
        <a
          className="App-link"
          href="https://web3emporium.com"
          target="_blank"
          rel="noopener noreferrer"
          >

          Web3Emporium.com
        </a>
        <p>
          Stay tuned. This is just the beginning.
        </p>
        

        <Iframe 
        id="inlineExample" 
        title="Inline Frame Example"
        class="airtable-embed" 
        src="https://airtable.com/embed/shrIe2IqUGZ43NnJ6?backgroundColor=green" 
        frameborder="0" 
        onmousewheel="" 
        width="100%" 
        height="800" 
        >

        </Iframe>


          <p>
          Web3 Emporium - A Web3 Experiment in Cryptovoxels Metaverse
        </p>
        <p>
          Parcels courtesy of  0x6f35B0Cfc58Eb1e21eeF8a439BbB0cE4C929d32a, 0xb476a9eA9376B04972B40b40a4CA91d3E5f964a7, 0x5ab52e17133a2d545b806a468b47650b077985dc, 0x00a2Ddfa736214563CEa9AEf5100f2e90c402918
        </p>

      </header>
    </div>
  );
}

export default App;











